import { useState, useEffect, useCallback, useMemo } from 'react';

import CompositeSelection from 'bloko/blocks/compositeSelection';
import ConversionNumber from 'bloko/blocks/conversion';
import FormItem from 'bloko/blocks/form/FormItem';
import Gap from 'bloko/blocks/gap';
import { CrossScaleSmallEnclosedFalse, IconColor } from 'bloko/blocks/icon';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { filterUniqueLeavesOnly, fromTree } from 'bloko/common/tree/treeCollectionHelper';
import { AdditionalDefault } from 'bloko/common/tree/types';

import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';

const TrlKeys = {
    fieldTitle: 'resumeBuilder.industry',
    any: 'resume.advancedSearch.field.professional_role.any',
    add: 'vacancy.search.industry.select',
    edit: 'vacancy.search.industry.select.full',
    placeholder: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
    save: 'treeselector.save',
    cancel: 'treeselector.cancel',
    youChose: 'resumeSearch.profAreaTree.youChose',
    roleOne: 'resumeSearch.specialization.1',
    roleTwo: 'resumeSearch.specialization.2',
    roleFive: 'resumeSearch.specialization.5',
};

const MAX_SHOW_TAGS = 10;

const IndustryFilter: TranslatedComponent = ({ trls }) => {
    const industryTree = useSelector((state) => state.industriesTree);
    const industryTreeCollection = useMemo(() => fromTree(industryTree), [industryTree]);

    const [collection, setCollection] = useState<TreeCollection<AdditionalDefault>>();
    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const [showTags, setShowTags] = useState<boolean>(false);
    const selectedValues = useSelector(
        ({ advancedVacancySearch }) => advancedVacancySearch.selectedValues[CriteriaKey.Industry]
    );

    const changeIndustries = useCallback(
        (selectedItems: Array<string>) => {
            if (!collection) {
                return;
            }
            const newIndustries: string[] = [];
            selectedItems.forEach((item) => {
                newIndustries.push(item);
            });
            const uniqueRoles = [...new Set(newIndustries)];
            setCheckedValues(uniqueRoles);
        },
        [collection]
    );

    useEffect(() => {
        setCollection(industryTreeCollection);
    }, [industryTreeCollection]);

    useEffect(() => {
        if (!collection) {
            return;
        }
        if (selectedValues && selectedValues.length > 0) {
            changeIndustries(selectedValues);
        }
    }, [changeIndustries, collection, selectedValues]);

    useEffect(() => {
        setShowTags(checkedValues.length > 0 && checkedValues.length <= MAX_SHOW_TAGS);
    }, [checkedValues]);

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            {checkedValues.map((id) => (
                <input key={id} type="hidden" name={CriteriaKey.Industry} value={id} />
            ))}
            <FormItem baseline>
                {checkedValues.length > MAX_SHOW_TAGS && (
                    <FormItem>
                        {`${trls[TrlKeys.youChose]} `}
                        <Link
                            appearance={LinkAppearance.Pseudo}
                            onClick={() => {
                                setShowTags(!showTags);
                            }}
                        >
                            <ConversionNumber
                                value={checkedValues.length}
                                one={trls[TrlKeys.roleOne]}
                                some={trls[TrlKeys.roleTwo]}
                                many={trls[TrlKeys.roleFive]}
                            />
                        </Link>
                        <span className="profareatree-clear">
                            <CrossScaleSmallEnclosedFalse
                                onClick={() => {
                                    changeIndustries([]);
                                }}
                                initial={IconColor.Gray60}
                            />
                        </span>
                    </FormItem>
                )}
                {!collection && <Loading scale={LoadingScale.Small} />}
                {collection && (
                    <CompositeSelection
                        collection={collection}
                        treeFilter={filterUniqueLeavesOnly}
                        value={checkedValues}
                        onChange={changeIndustries}
                        title={trls[TrlKeys.fieldTitle]}
                        trl={{
                            submit: trls[TrlKeys.save],
                            cancel: trls[TrlKeys.cancel],
                            searchPlaceholder: trls[TrlKeys.placeholder],
                            notFound: trls[TrlKeys.notFound],
                        }}
                    >
                        {({ showTreeSelectorPopup, renderTagList }) => (
                            <>
                                {showTags && renderTagList({ stretched: true, removable: true, nested: true })}
                                <FormItem baseline>
                                    {checkedValues.length === 0 && (
                                        <Gap Element="span" right>
                                            {trls[TrlKeys.any]}
                                        </Gap>
                                    )}
                                    <Link
                                        appearance={LinkAppearance.Pseudo}
                                        onClick={showTreeSelectorPopup}
                                        data-qa="industry-addFromList"
                                    >
                                        {trls[checkedValues.length === 0 ? TrlKeys.add : TrlKeys.edit]}
                                    </Link>
                                </FormItem>
                            </>
                        )}
                    </CompositeSelection>
                )}
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(IndustryFilter);
