import { FC } from 'react';

import { FormLegend } from 'bloko/blocks/form';
import Select, { Option } from 'bloko/blocks/select';

import { CurrencyType } from 'lux/models/currencies.types';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import Currency from 'src/components/Currency';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

interface CurrencySelectProps {
    currency: CurrencyType;
    setCurrency: (value: CurrencyType) => void;
}

const CurrencySelect: FC<CurrencySelectProps> = ({ currency, setCurrency }) => {
    const currencies = useSelector((state) => state.currencies.list.filter((currency) => currency.site === true));
    const defaultCurrencyCode = useSelector((state) => state.currencies.default);
    const isZarplataPlatform = useIsZarplataPlatform();

    if (isZarplataPlatform) {
        return (
            <FormLegend>
                <Currency value={defaultCurrencyCode} />
            </FormLegend>
        );
    }

    return (
        <Select
            flexible
            name={CriteriaKey.CurrencyCode}
            value={currency}
            onChange={({ target }) => {
                setCurrency(target.value as CurrencyType);
            }}
            data-qa="advanced-search__select-currency"
        >
            {currencies.map((option) => (
                <Option key={option.code} value={option.code} data-qa={`currency-code-${option.code}`}>
                    <Currency value={option.code} />
                </Option>
            ))}
        </Select>
    );
};

export default CurrencySelect;
