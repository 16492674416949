import { FC, PropsWithChildren } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import { BannerPlace } from 'lux/models/banners';
import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const RightBanners: FC<PropsWithChildren> = () => {
    const rightBanners = useSelector(({ banners }) => banners[BannerPlace.RIGHT_COLUMN]);

    return (
        <div className="banner-place-frame">
            <Column xs="4" s="8" m="4" l="4">
                {rightBanners?.map((banner) => (
                    <div key={banner.cId} className="banner-place-fat-wrapper">
                        <AdsExternalBanner {...banner} />
                        <VSpacing base={6} />
                    </div>
                ))}
            </Column>
        </div>
    );
};

export default translation(RightBanners);
