import { useState, useMemo, ReactElement } from 'react';

import { FormItem } from 'bloko/blocks/form';
import InputText from 'bloko/blocks/inputText';
import MetroPin from 'bloko/blocks/metroPin';
import Suggest, { SuggestLayer } from 'bloko/blocks/suggest';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProvider } from 'bloko/blocks/suggest/types';
import TagList from 'bloko/blocks/tagList';
import Tag from 'bloko/blocks/tagList/tag';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MetroItem } from 'lux/models/search/advancedSearch';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';
import MetroLine from 'src/components/AdvancedSearchFilters/MetroLine';

const TrlKeys = {
    fieldTitle: 'searchForm.label.metro',
};

interface MetroProps {
    area: string;
    checkedValues: MetroItem[];
    setCheckedValues: (v: MetroItem[]) => void;
    autoInvite?: boolean;
}

const WILDCARD = '%QUERY%';
const REMOTE = `/autosuggest/multiprefix/v2?q=${WILDCARD}&d=metro_{lang}_{cityId}`;

const Metro: TranslatedComponent<MetroProps> = ({ trls, area, checkedValues, setCheckedValues, autoInvite }) => {
    const metroCities = useSelector((state) => state.metroCities);
    const lang = useSelector((state) => state.langs[0]);
    const [inputValue, setInputValue] = useState('');

    const dataProvider = useMemo(
        () => createRemoteDataProvider(REMOTE.replace('{lang}', lang).replace('{cityId}', area), WILDCARD),
        [area, lang]
    ) as DataProvider<MetroItem>;

    if (!metroCities.includes(area)) {
        return null;
    }

    const handleSuggestChange = (value: MetroItem) => {
        if (value) {
            const isLine = value.id?.indexOf('.') === -1;
            let stationAlreadyOnSelectedLine = false;
            const excludingStationsIds: string[] = [];
            if (isLine) {
                checkedValues.forEach((checkedValue) => {
                    if (value.id && checkedValue.id && checkedValue.id.indexOf(`${value.id}.`) !== -1) {
                        excludingStationsIds.push(checkedValue.id);
                    }
                });
            } else {
                stationAlreadyOnSelectedLine = checkedValues.some((checkedValue) => {
                    return checkedValue.id?.indexOf('.') === -1 && value.id?.indexOf(`${checkedValue.id}.`) !== -1;
                });
            }
            const newValues = checkedValues.filter((checkedValue) => !excludingStationsIds.includes(checkedValue.id));
            setCheckedValues(stationAlreadyOnSelectedLine ? newValues : [...newValues, value]);
            setInputValue('');
        }
    };

    const handleRemove = (id: string) => {
        setCheckedValues(checkedValues.filter((value) => value.id !== id));
    };

    const preparedTags: ReactElement[] = [];
    const hiddenFormInputs: ReactElement[] = [];
    checkedValues.forEach(({ id, text, color, line }) => {
        preparedTags.push(
            <Tag key={id} onRemove={() => handleRemove(id || '')}>
                {line ? <MetroPin color={color} /> : <MetroLine color={color} />}
                {text}
            </Tag>
        );
        hiddenFormInputs.push(<input key={id} type="hidden" name="metro" value={id || ''} />);
    });

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            {checkedValues.length > 0 && (
                <FormItem>
                    {hiddenFormInputs}
                    <TagList removable items={preparedTags} />
                </FormItem>
            )}
            <FormItem>
                <Suggest
                    dataProvider={dataProvider}
                    itemContent={({ text, color, line }: MetroItem) => (
                        <div data-qa="address-edit-metro-suggest-item">
                            {line ? <MetroPin color={color} /> : <MetroLine color={color} />}
                            <span data-qa="address-edit-metro-suggest-item">{text}</span>
                        </div>
                    )}
                    onChange={handleSuggestChange}
                    value={{ text: inputValue }}
                    layer={autoInvite ? SuggestLayer.AboveOverlayContent : SuggestLayer.AboveContent}
                >
                    <InputText data-qa="searchform__subway-input" value={inputValue} onChange={setInputValue} />
                </Suggest>
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(Metro);
