import { useContext, useEffect, useState } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import AutoInviteContext from 'src/components/AutoInviteModal/common/AutoInviteContext';
import translation from 'src/components/translation';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';
import Area from 'src/components/AdvancedSearchFilters/Area';
import District from 'src/components/AdvancedSearchFilters/District';
import Metro from 'src/components/AdvancedSearchFilters/Metro';
import Relocation from 'src/components/AdvancedSearchFilters/Relocation';

const TrlKeys = {
    fieldTitle: 'employer.resumesSearch.region',
    districtFieldTitle: 'clusters.district',
};

interface RegionProps {
    noDistricts?: boolean;
    noRelocation?: boolean;
    autoInvite?: boolean;
}

const Region: TranslatedComponent<RegionProps> = ({
    trls,
    noDistricts = false,
    noRelocation = false,
    autoInvite = false,
}) => {
    const isHeadHunterPlatform = useIsHeadHunterPlatform();

    const selectedAreas = useSelector((state) => state.advancedSearch[CriteriaKey.Area]);
    const selectedMetro = useSelector((state) => state.advancedSearch[CriteriaKey.Metro]) || [];
    const selectedDistricts = useSelector((state) => state.advancedSearch[CriteriaKey.District]);
    const [areaValues, setAreaValues] = useState(() => selectedAreas?.map(String) || []);
    const [metroValues, setMetroValues] = useState(selectedMetro || []);
    const [districtValues, setDistrictValues] = useState(selectedDistricts || []);

    const autoInviteHandlerFormChange = useContext(AutoInviteContext);

    const handleAreaChanged = (selectedItems: string[]) => {
        setAreaValues(selectedItems);
        setMetroValues([]);
        setDistrictValues([]);
    };

    useEffect(() => {
        autoInviteHandlerFormChange?.();
    }, [autoInviteHandlerFormChange, areaValues, metroValues, districtValues]);

    return (
        <>
            <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
                <div data-qa="advanced-search__selected-regions">
                    {areaValues.map((id) => (
                        <input key={id} type="hidden" name="area" value={id} />
                    ))}
                    <Area checkedValues={areaValues} onChange={handleAreaChanged} autoInvite={autoInvite} />
                    {!noRelocation && isHeadHunterPlatform && (
                        <>
                            <VSpacing base={2} />
                            <Relocation areas={areaValues} autoInvite={autoInvite} />
                        </>
                    )}
                </div>
            </AdvancedFilter>
            {areaValues.length === 1 && (
                <div data-qa="advanced-search__selected-metro">
                    {!noDistricts && (
                        <District
                            checkedValues={districtValues}
                            area={areaValues[0]}
                            setCheckedValues={setDistrictValues}
                            title={trls[TrlKeys.districtFieldTitle]}
                            autoInvite={autoInvite}
                        />
                    )}
                    <Metro
                        area={areaValues[0]}
                        checkedValues={metroValues}
                        setCheckedValues={setMetroValues}
                        autoInvite={autoInvite}
                    />
                </div>
            )}
        </>
    );
};

export default translation(Region);
