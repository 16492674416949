import { useState, useEffect, useCallback, useContext } from 'react';

import CompositeSelection from 'bloko/blocks/compositeSelection';
import ConversionNumber from 'bloko/blocks/conversion';
import FormItem from 'bloko/blocks/form/FormItem';
import Gap from 'bloko/blocks/gap';
import { CrossScaleSmallEnclosedFalse, IconColor } from 'bloko/blocks/icon';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { filterUniqueLeavesOnly, fromTree } from 'bloko/common/tree/treeCollectionHelper';

import { ProfessionalRoleTreeAdditional } from 'lux/models/professionalRoleTree';
import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import AutoInviteContext from 'src/components/AutoInviteModal/common/AutoInviteContext';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';

const TrlKeys = {
    fieldTitle: 'resume.advancedSearch.field.professional_role',
    any: 'resume.advancedSearch.field.professional_role.any',
    add: 'resume.advancedSearch.field.professional_role.add',
    edit: 'resume.advancedSearch.field.professional_role.edit',
    placeholder: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
    save: 'treeselector.save',
    cancel: 'treeselector.cancel',
    youChose: 'resumeSearch.profAreaTree.youChose',
    roleOne: 'resumeSearch.specialization.1',
    roleTwo: 'resumeSearch.specialization.2',
    roleFive: 'resumeSearch.specialization.5',
};

const MAX_SHOW_TAGS = 10;

const ProfessionalRole: TranslatedComponent<{ autoInvite?: boolean }> = ({ trls, autoInvite }) => {
    const professionalRoleTree = useSelector(({ professionalRoleTree }) => professionalRoleTree);
    const [collection, setCollection] = useState<TreeCollection<ProfessionalRoleTreeAdditional>>();
    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const [showTags, setShowTags] = useState<boolean>(false);
    const professionalRolesFromSearch = useSelector((state) =>
        autoInvite ? state.autoInvite?.filters.professionalRoles : state.professionalRolesFromSearch
    );
    const autoInviteHandlerFormChange = useContext(AutoInviteContext);

    const changeRoles = useCallback(
        (selectedItems: Array<string | number>) => {
            if (!collection) {
                return;
            }
            const newRoles: string[] = [];
            const topLvl = collection.getTopLevel().map(({ id }) => id);
            selectedItems.forEach((item) => {
                if (topLvl.includes(item)) {
                    // for top categories
                    // using children
                    newRoles.push(...collection.getChildrenIds(String(item)));
                } else {
                    // or push new role
                    newRoles.push(String(item));
                }
            });
            // delete duplicate
            const uniqueRoles = [...new Set(newRoles)];
            setCheckedValues(uniqueRoles);
        },
        [collection]
    );

    // prepare collection
    useEffect(() => {
        setCollection(fromTree(professionalRoleTree.items));
    }, [professionalRoleTree]);

    // set value from get params
    useEffect(() => {
        if (!collection) {
            return;
        }
        if (professionalRolesFromSearch && professionalRolesFromSearch.length > 0) {
            changeRoles(professionalRolesFromSearch);
        }
    }, [changeRoles, collection, professionalRolesFromSearch]);

    // tag view (can be hidden by user)
    useEffect(() => {
        setShowTags(checkedValues.length > 0 && checkedValues.length <= MAX_SHOW_TAGS);
    }, [checkedValues]);

    useEffect(() => {
        autoInviteHandlerFormChange?.();
    }, [autoInviteHandlerFormChange, checkedValues]);

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            {checkedValues.map((id) => (
                <input key={id} type="hidden" name={CriteriaKey.ProfessionalRole} value={id} />
            ))}
            <FormItem baseline>
                {checkedValues.length > MAX_SHOW_TAGS && (
                    <FormItem>
                        {`${trls[TrlKeys.youChose]} `}
                        <Link
                            appearance={LinkAppearance.Pseudo}
                            onClick={() => {
                                setShowTags(!showTags);
                            }}
                        >
                            <ConversionNumber
                                value={checkedValues.length}
                                one={trls[TrlKeys.roleOne]}
                                some={trls[TrlKeys.roleTwo]}
                                many={trls[TrlKeys.roleFive]}
                            />
                        </Link>
                        <span className="profareatree-clear">
                            <CrossScaleSmallEnclosedFalse
                                onClick={() => {
                                    changeRoles([]);
                                }}
                                initial={IconColor.Gray60}
                            />
                        </span>
                    </FormItem>
                )}
                {!collection && <Loading scale={LoadingScale.Small} />}
                {collection && (
                    <CompositeSelection
                        collection={collection}
                        treeFilter={filterUniqueLeavesOnly}
                        value={checkedValues}
                        onChange={changeRoles}
                        title={trls[TrlKeys.fieldTitle]}
                        trl={{
                            submit: trls[TrlKeys.save],
                            cancel: trls[TrlKeys.cancel],
                            searchPlaceholder: trls[TrlKeys.placeholder],
                            notFound: trls[TrlKeys.notFound],
                        }}
                    >
                        {({ showTreeSelectorPopup, renderTagList }) => (
                            <>
                                {showTags && renderTagList({ stretched: true, removable: true })}
                                <FormItem baseline>
                                    {checkedValues.length === 0 && (
                                        <Gap Element="span" right>
                                            {trls[TrlKeys.any]}
                                        </Gap>
                                    )}
                                    <Link
                                        appearance={LinkAppearance.Pseudo}
                                        onClick={showTreeSelectorPopup}
                                        data-qa="resumesearch__profroles-switcher"
                                    >
                                        {trls[checkedValues.length === 0 ? TrlKeys.add : TrlKeys.edit]}
                                    </Link>
                                </FormItem>
                            </>
                        )}
                    </CompositeSelection>
                )}
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(ProfessionalRole);
